import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, Dropdown }  from 'react-bootstrap-latest';

import Classes from '../Classes';

const ButtonDropdown = ({
  options, ...dropdownProps
}) => (
  <DropdownButton
    variant={Classes.primary}
    className={Classes.roundAllCorners}
    {...dropdownProps}
  >
    <ul class="dropdown-content">
    {
      options.map(option => (
        <li key={option.code}>
        <Dropdown.Item
          key={option.code}
          eventKey={option.code}
        >
          {option.name}
        </Dropdown.Item>
        </li>
      ))
    }
    </ul>

  </DropdownButton>
);

ButtonDropdown.propTypes = {
  ...DropdownButton.propTypes,
  options: PropTypes.array.isRequired
};

export default ButtonDropdown;
